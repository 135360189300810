import React from "react";
import GitHubGraph from "../GitHubGraph/GitHubGraph";
import "./About.css";

const aboutSections = [
  {
    image: "./images/Taylor_Building.jpg",
    title: "Education",
    description: `From the moment I embarked on my web development journey in 2018
    at BYU-Idaho, I discovered a profound passion for crafting digital
    experiences. My initial exposure in a web development class fueled
    my enthusiasm, leading me to delve deeper into elective courses
    dedicated to honing my skills.`,
    extraClasses: "about-image-shadow",
  },
  {
    image: "./images/SLC.jpg",
    title: "Professional Experience",
    description: `Kicking off my professional journey in August 2019, I immersed
    myself in the world of web development through an enriching
    internship at FamilySearch, followed by a rewarding year-long
    contract position. Presently, I proudly serve as a Front-End
    Engineer at Lucid Software.`,
    extraClasses: "about-image-shadow",
  },
  {
    image: "./images/Brand/logo.png",
    title: "Personal Life",
    description: `Beyond the realms of my professional life, I'm a perpetual
    learner. Whether it's through online courses, insightful articles,
    or personal projects, I am committed to a continuous journey of
    growth and skill refinement. Join me as I navigate the ever-evolving landscape of web
    technologies, where each line of code is an opportunity to create
    something extraordinary.`,
    imageStyle: { maxWidth: "200px", height: "auto" },
  },
];

const AboutSection = ({ image, title, description, imageStyle, index }) => (
  <div className="about-section">
    {index % 2 !== 0 && (
      <div className="about-image-container">
        <img
          className="about-image"
          src={image}
          alt={title}
          style={imageStyle}
        />
      </div>
    )}
    <div>
      <h3>{title}</h3>
      <p className="about-description">{description}</p>
    </div>
    {index % 2 === 0 && (
      <div className="about-image-container">
        <img
          className="about-image"
          src={image}
          alt={title}
          style={imageStyle}
        />
      </div>
    )}
  </div>
);

const About = () => (
  <div>
    <h2 className="About-heading">About Me</h2>
    <div style={{ gap: "40px", marginTop: "40px" }}>
      {aboutSections.map((section, index) => (
        <AboutSection {...section} key={index} index={index} />
      ))}
    </div>
    <GitHubGraph />
  </div>
);

export default About;
