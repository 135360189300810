import { useEffect, useState } from "react";
import axios from "axios";
import "./GitHubGraph.css";

// Function to get color class based on number of commits
const getColorClass = (commits) => {
  if (commits >= 8) return "day-4";
  if (commits >= 5) return "day-3";
  if (commits >= 2) return "day-2";
  if (commits >= 1) return "day-1";
  return "day-0";
};

function GitHubGraph() {
  const [commitData, setCommitData] = useState(undefined);

  useEffect(() => {
    axios
      .get(`https://main-server-production-bbdf.up.railway.app/githubCommitData`)
      .then((res) => {
        setCommitData(res.data);
      });
  }, []);

  if (commitData) {
    let columnIndex = 0;
    const days = Array.from(
      { length: Object.keys(commitData.data).length },
      (_, i) => {
        const date = new Date(commitData.startDate);
        date.setDate(date.getDate() + i);
        const mountainTime = new Date(
          date.toLocaleString("en-US", { timeZone: "America/Denver" })
        );
        const dateString = mountainTime.toISOString().split("T")[0];
        const commits = commitData.data[dateString] || 0;
        const colorClass = getColorClass(commits);
        if (i % 7 === 0 && i >= 7) {
          columnIndex++;
        }

        return (
          <div
            key={`dateString-${i}`}
            style={{ gridColumn: columnIndex + 1, gridRow: (i % 7) + 1 }}
            className={`day ${colorClass}`}
          />
        );
      }
    );

    return (
      <div style={{ margin: "40px auto", marginBottom: 0 }}>
        <a
          target="_blank"
          rel="noreferrer"
          className="graph-container"
          href="https://github.com/twillia7"
        >
          <div id="activity-grid">{days.reverse()}</div>
          <div className="integration-container">
            <p>Integrated with </p>
            <img
              src="/images/GitHub_Logo.png"
              alt="GitHub Logo"
              className="github-integration-logo"
            />
          </div>
        </a>
      </div>
    );
  }
  return null;
}

export default GitHubGraph;
