import SocialLinks from "./components/SocialLinks/SocialLinks";
import Profile from "./components/profile/Profile";
import Timeline from "./components/Timeline/Timeline";
import ProjectsSlider from "./components/ProjectsSlider/ProjectsSlider";
import About from "./components/About/About";

import "./App.css";

const ICON_TYPES = {
  html: {
    name: "HTML",
    icon: "html.png",
    date: "2017 - Present",
  },
  css: {
    name: "CSS",
    icon: "css.png",
    date: "2017 - Present",
  },
  js: {
    name: "JavaScript",
    icon: "js.png",
    date: "2017 - Present",
  },
  node: {
    name: "Node.js",
    icon: "node.png",
    date: "2018 - Present",
  },
  react: {
    name: "React",
    icon: "react.png",
    date: "2019 - Present",
  },
  ts: {
    name: "TypeScript",
    icon: "ts.png",
    date: "2019 - Present",
  },
};

const ExperienceIcon = ({ type }) => {
  return (
    <div className="experience-icon-box">
      <img
        src={`/images/Icons/${type.icon}`}
        alt=""
        className="experience-icon"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h3 style={{ margin: "8px" }}>{type.name}</h3>
        <p style={{ margin: "8px" }}>{type.date}</p>
      </div>
    </div>
  );
};

function App() {
  return (
    <>
      <div className="App-container">
        <h1 className="App-title">Taylor Williams</h1>
        <Profile />
        <SocialLinks />
      </div>
      <div className="About-section">
        <div className="App-container">
          <About />
        </div>
      </div>
      <ProjectsSlider />
      <div className="App-container">
        <h2>Experience</h2>
        <div className="experience-icon-container">
          {Object.keys(ICON_TYPES).map((key, index) => (
            <ExperienceIcon
              key={`icon-${key}-${index}`}
              type={ICON_TYPES[key]}
            />
          ))}
        </div>
        <Timeline />
      </div>
    </>
  );
}

export default App;
