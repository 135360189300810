const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function getSpanString(start, end) {
  const startDate = new Date(start);
  const startString = `${
    MONTHS[startDate.getUTCMonth()]
  } ${startDate.getUTCFullYear()}`;

  if (!end) {
    return `${startString} - Present`;
  }

  const endDate = new Date(end);
  return `${startString} - ${
    MONTHS[endDate.getUTCMonth()]
  } ${endDate.getUTCFullYear()}`;
}

export function monthDiff(d1, d2) {
  let months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}
