import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import Backdrop from "./backdrop/Backdrop";
import "./Modal.css";

function ModalOverlay({ className, style, header, children, footer }) {
  const content = (
    <div className={`modal ${className}`} style={style}>
      <div className={`modal__content`}>{children}</div>
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById("modal-hook"));
}

export default function Modal(props) {
  return (
    <>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="modal"
      >
        <ModalOverlay {...props} />
      </CSSTransition>
    </>
  );
}
