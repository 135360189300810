import React from "react";
import "./ProjectsSlider.css";
import projectData from "../../data/projects.json";

const Project = ({ background, title, textColor, backgroundImage }) => (
  <div className="project" style={{ background }}>
    <h3 style={{ color: textColor }}>{title}</h3>
    {backgroundImage && (
      <img className="projectBackgroundImage" src={backgroundImage} alt="" />
    )}
    <div />
  </div>
);

const ProjectsSlider = () => (
  <div className="projectsContainer">
    <h2>Projects</h2>
    <div className="projectContainer">
      {projectData.map((project, index) => (
        <Project {...project} key={index} />
      ))}
    </div>
  </div>
);

export default ProjectsSlider;
