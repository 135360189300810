import React, { useState } from "react";
import "./Profile.css";

function Profile() {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <div>
      <div
        style={{
          display: isImageLoaded ? "none" : "block",
        }}
        className="image-div"
      />
      <img
        style={{
          display: isImageLoaded ? "block" : "none",
        }}
        className="image"
        alt=""
        src="/images/profile.jpg"
        onLoad={() => setIsImageLoaded(true)}
      />
    </div>
  );
}

export default Profile;
