import React, { useEffect, useState } from "react";

import timelineData from "../../data/timeline.json";
import { getSpanString, monthDiff } from "../../utils/date";
import Modal from "../Modal/Modal";
import "./Timeline.css";

const EventRole = ({ role }) => (
  <div className="timeline-modal-role">
    <h3 className="timeline-modal-role-title">{`${role?.title} `}</h3>
    &nbsp;
    <h5 className="timeline-modal-role-date">
      {`(${getSpanString(role?.start, role?.end)})`}
    </h5>
  </div>
);

const EventDetail = ({ detail, index }) => (
  <p key={`Detail-${index}`} className="timeline-modal-role-details">
    - {detail}
  </p>
);

function Timeline() {
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [totalMonths, setTotalMonths] = useState();
  const [hoveredEvent, setHoveredEvent] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [showModal, setShowModal] = useState(false);

  const closeModalHandler = () => setShowModal(false);
  const toggleShowModal = () => {
    setShowModal(!showModal);
  };
  const selectEvent = (event) => {
    setSelectedEvent(event);
    toggleShowModal();
  };

  useEffect(() => {
    let startDate = null;
    const endDate = new Date();

    timelineData.events.forEach((event) => {
      if (!startDate || startDate > new Date(event.start)) {
        startDate = new Date(event.start);
      }
    });

    const monthDiffCount = monthDiff(startDate, endDate);

    setStart(startDate);
    setEnd(endDate);
    setTotalMonths(monthDiffCount);
  }, []);

  return (
    <div style={{ padding: "16px 0" }}>
      <Modal show={showModal} onCancel={closeModalHandler}>
        <h2 className="timeline-modal-title">{selectedEvent?.place}</h2>
        <h5 className="timeline-modal-date">
          {getSpanString(selectedEvent?.start, selectedEvent?.end)}
          {selectedEvent?.gpa ? ` | GPA: ${selectedEvent?.gpa}` : ""}
        </h5>
        <div className="timeline-modal-roles">
          {selectedEvent?.type !== "EDU" &&
            selectedEvent?.roles?.map((role, index) => (
              <EventRole key={`event-role-${index}`} role={role} />
            ))}
        </div>
        {selectedEvent?.details && (
          <h4 className="timeline-modal-role-subtitle">
            {selectedEvent?.type === "EDU"
              ? "Relavent Coursework:"
              : "Details:"}
          </h4>
        )}
        {selectedEvent?.details?.map((detail, index) => (
          <EventDetail
            detail={detail}
            index={index}
            key={`event-detail-${index}`}
          />
        ))}
      </Modal>
      <div
        className="resume-container"
        style={{
          gridTemplateColumns: `repeat(${totalMonths}, calc(${
            100 / totalMonths
          }%))`,
        }}
      >
        {totalMonths &&
          [...Array(totalMonths)].map((_, i) => {
            let tempStartDate = new Date(start);
            tempStartDate.setMonth(start?.getMonth() + i);

            if (i === 0) {
              return (
                <div
                  key={`month-${i}`}
                  style={{
                    gridColumn: "0 / 0",
                    gridRow: "1 / 3",
                    bottom: 0,
                  }}
                >
                  {start?.getFullYear()}
                </div>
              );
            }
            if (start?.getMonth() + 1 + (i % 12) === 12) {
              return (
                <div
                  key={`year-${i}`}
                  style={{
                    gridColumnStart: i + 2,
                    gridColumnEnd: i + 2,
                    gridRow: "1 / 4",
                  }}
                >
                  {tempStartDate.getFullYear() + 1}
                </div>
              );
            }

            return null;
          })}
        <div
          style={{
            backgroundColor: "lightgray",
            gridColumn: `1 / ${totalMonths + 1}`,
            gridRow: "3 / 4",
          }}
        />
        {end &&
          start &&
          timelineData.events.map((event, index) => {
            const eventStart = new Date(event.start);
            const eventEnd = event.end ? new Date(event.end) : new Date();

            const eventStartGrid = totalMonths - monthDiff(eventStart, end) + 1;
            const eventEndGrid = event.end
              ? totalMonths - monthDiff(eventEnd, end) + 1
              : totalMonths;

            return (
              <div
                key={`event-${index}`}
                style={{
                  gridColumnStart: eventStartGrid,
                  gridColumnEnd: eventEndGrid + 1,
                  gridRow: "3 / 4",
                  backgroundColor: "black",
                  display: hoveredEvent === event.place ? "" : "none",
                }}
                key={`event-${index}`}
              />
            );
          })}
      </div>
      <div className="timeline-event-container">
        {timelineData.events
          .slice(0)
          .reverse()
          .map((event, index) => {
            return (
              <div
                key={`event-${index}`}
                onMouseOver={() => {
                  setHoveredEvent(event.place);
                }}
                onMouseOut={() => {
                  setHoveredEvent(null);
                }}
                className="timeline-event"
                onClick={() => selectEvent(event)}
              >
                <h5>{event.place}</h5>
                <h6>{getSpanString(event.start, event.end)}</h6>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Timeline;
